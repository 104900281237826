function animateOffsetToContent(linkTarget) {
  let topOffset = $(linkTarget).offset().top;
  let navContainer = '.fixed-top';
  if ($('.mobile-nav').is(':visible')) {
    if ($('#burger').is(':checked')) {
      navContainer = '.mobile-menu input~nav';
      $('#burger').trigger('click');
    }
  }

  topOffset -= $(navContainer).height();

  $('html, body').animate({
    scrollTop: topOffset,
  }, 900);
}

$(() => {
  const $els = $('div[id^=proof]');
  let i = 0;
  const len = $els.length;

  $els.slice(1).hide();
  setInterval(() => {
    $els.eq(i).fadeOut(() => {
      i = (i + 1) % len;
      $els.eq(i).fadeIn();
    });
  }, 2500);
});

$(document).ready(() => {
  const $fourBooksSelector = $('select#fourBooksSelected');
  const $fourBooksForm = $('#four-books');
  const $fourBooksSelectedOption = $('#fourBooksSelected');
  const $fourBooksSubmit = $('.four-books');

  $fourBooksSelector.change((e) => {
    $(`.purchase-options [data-id="${$(e.currentTarget).val()}"]`).trigger('click');
  });

  $fourBooksForm.on('submit', (e) => {
    e.preventDefault();
    const productID = $fourBooksSelectedOption.val();
    const url = `https://shop.consciousstories.com/cart/add?id=${productID}`;
    window.open(url, '_blank');
  });


  $fourBooksSubmit.click((e) => {
    e.preventDefault();
    $fourBooksForm.submit();
  });

  // Offset Fixed Navigation
  $('.nav-link, .mobile-links a, .btn-prime').click((e) => {
    const linkTarget = $(e.currentTarget).attr('href');

    if (linkTarget === '#' || linkTarget.charAt(0) !== '#') {
      return;
    }
    e.preventDefault();

    animateOffsetToContent(linkTarget);
  });

  // Mobile Menu Trigger
  const $mobileBookSelector = $('select#mobileBookSelector');
  $mobileBookSelector.change(e => {
    $(`.tab-section [data-id="${$(e.currentTarget).val()}"]`).trigger('click');
    // Mobile Tab Dropdown Smooth Scroll
    animateOffsetToContent('#nav-tabContent');
  });

  $('.slider').slick({
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [{
      breakpoint: 1199,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 2,
        dots: true,
      },
    }, {
      breakpoint: 991,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 2,
        dots: true,
      },
    }, {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
        dots: true,
      },
    }, ],
  });

  // Wheel Click Modal
  $('button.btn-prime').on('mousedown', e => {
    if (e.which === 2) {
      e.preventDefault();
      e.stopPropagation();
      $(e.currentTarget).trigger('click');
    }
  });
});
